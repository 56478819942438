import type { NextPage } from "next";
import { useRouter } from "next/router";
import {
  Container,
  Grid,
  LoadingButton,
  TextField,
  Typography,
} from "@helo/ui";
import { FormikValues, useFormik } from "formik";

import CirclesLeft from "~/images/61973d43f33809e36ca25295_hero-circles-left.svg";
import CirclesRight from "~/images/61973d430b843bda877fa473_hero-circles-right.svg";
import Truck from "~/images/helo-tracking-mascot.png";
import { BrandName } from "~/constants/brand";
import toast from "react-hot-toast";

/**
 * Validator for Tracking Number
 */
const validator = (values: FormikValues) => {
  const errors: any = {};

  if (!values.trackingNumber || values.trackingNumber.length === 0) {
    errors.trackingNumber = "Enter a valid tracking number";
  }

  return errors;
};

const Home: NextPage = () => {
  const router = useRouter();

  const formik = useFormik({
    initialValues: {
      trackingNumber: "",
    },
    validate: validator,
    onSubmit: (values, helpers) => {
      const loader = toast.loading("We are processing your request...");
      helpers.setSubmitting(true);
      const { trackingNumber } = values;

      router
        .push({
          pathname: "/track",
          query: { trackingNumber },
        })
        .then(() => {
          toast.dismiss(loader);
        });
    },
  });

  return (
    <>
      <Container className="section" component="section">
        <div className="bg-graphic-left">
          <img
            className="bg-graphic-left__asset"
            src={CirclesLeft.src}
            alt=""
          />
        </div>

        <div className="bg-graphic-right">
          <img
            className="bg-graphic-right__asset"
            src={CirclesRight.src}
            alt=""
          />
        </div>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              component="h1"
              fontWeight={600}
              className="heading-with-flair"
              sx={({ palette }) => ({
                m: 0,
                position: "relative",
                color: palette.accentPurple.main,
              })}
            >
              {BrandName} Tracking
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography
              variant="h2"
              component="h2"
              fontWeight={700}
              sx={({ palette, breakpoints }) => ({
                color: palette.secondary.main,
                display: "inline-flex",
                flexDirection: "column",
                [breakpoints.up("md")]: { display: "inline" },
              })}
            >
              <Typography component="span" variant="inherit">
                Track{" "}
                <Typography
                  component="span"
                  variant="inherit"
                  sx={({ palette }) => ({ color: palette.primary.main })}
                >
                  Your
                </Typography>{" "}
                Package
              </Typography>
              <img className="icon--truck" src={Truck.src} alt="" />
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{ display: "grid", alignContent: "center" }}
          >
            <form method="POST" name="trackForm" onSubmit={formik.handleSubmit}>
              <TextField
                fullWidth
                name="trackingNumber"
                label="Tracking Number"
                value={formik.values.trackingNumber}
                onChange={formik.handleChange}
                error={
                  formik.touched.trackingNumber &&
                  Boolean(formik.errors.trackingNumber)
                }
                helperText={
                  formik.touched.trackingNumber && formik.errors.trackingNumber
                }
                sx={{
                  backgroundColor: "#ffffff",
                }}
              />
              <p>
                <LoadingButton
                  variant="contained"
                  size="large"
                  type="submit"
                  name="Track"
                  value="Track"
                  loading={formik.isSubmitting}
                  sx={{ width: "100%" }}
                >
                  Track
                </LoadingButton>
              </p>
            </form>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Home;
